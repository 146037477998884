module.exports = {
  siteTitle: 'Look \'n Say', // <title>
  manifestName: 'Look \'n Say',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#ffffff',
  manifestThemeColor: '#ffffff',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-grayscale/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'แอปเรียนรู้การพูดสำหรับคนหูหนวก',
  subHeading: 'เพื่อการอยู่ร่วมกันในสังคม และโอกาสทางการศึกษา',

  // social
  socialLinks: [
    {
      icon: 'fa-line',
      name: 'LINE',
      url: 'http://nav.cx/c5hQV4G',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://www.facebook.com/LookAndSayThailand',
    },
  ],
  email: 'patipol@looknsay.com',
  phone: '000-00000',
  address: '2521/10 ถนนลาดพร้าว, คลองเจ้าคุณสิงห์, วังทองหลาง, กรุงเทพฯ 10310',
};
